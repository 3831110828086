import request from '@/utils/request';

/**
 * @param {Object} 查询菜单列表
 */
export function getMenuList(data) {
  return request({
    url: '/admin-menu/getList',
    method: 'post',
    data
  });
}

/**
 * @param {Object} data 保存菜单
 */
export function saveMenu(data) {
  return request({
    url: '/admin-menu/save',
    method: 'post',
    data
  });
}

/**
 * @param {Object} 删除菜单
 */
export function delMenu(data) {
  return request({
    url: '/admin-menu/del',
    method: 'post',
    data
  });
}

/**
 * @param {Object} 是否激活菜单
 */
export function updateIsActive(data) {
  return request({
    url: '/admin-menu/updateIsActive',
    method: 'post',
    data
  });
}
